<template>
  <div ref="newModal">
    <a-modal
      :getContainer="() => $refs.newModal"
      title="审核"
      visible
      :width="800"
      @cancel="onCancel"
      @ok="onOk"
      :maskClosable="false"
      :keyboard="false"
    >
      <section class="layout-modal">
        <section>
          <ZgyxFormGirdEdit
            ref="girdFormEdit"
            :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 16 }"
            :formData="formState"
            :configs="[
              {
                span: 24,
                type: 'radio',
                label: '审核意见',
                key: 'auditingStatus',
                options: useStatusList,
                rules: [{ required: true }]
              },
              {
                span: 24,
                type: 'textarea',
                label: '理由',
                key: 'remark',
                maxlength: 200,
                showCount: true,
                autoSize: { minRows: 6, maxRows: 6 }
              }
            ]"
            @update:formData="(formData) => (formState = formData)"
          >
          </ZgyxFormGirdEdit>
        </section>
      </section>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { message } from 'ant-design-vue';
import { PlusOutlined } from '@ant-design/icons-vue';
// apis
import { apiPostCountyAuditing } from '@src/apis';
// utils
// mixins
// configs
// components
import { ZgyxFormGirdEdit } from '@src/components';
export default {
  name: 'memberModal',
  components: { ZgyxFormGirdEdit, PlusOutlined },
  emits: ['onOk', 'onCancel'],
  props: {
    activeId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    const formState = {
      projectResultId: '',
      auditingStatus: 4,
      remark: ''
    };
    return {
      formState,
      useStatusList: [
        { name: '同意', value: 4 },
        { name: '驳回', value: 3 }
      ]
    };
  },
  computed: {
    ...mapGetters([])
  },
  mounted() {
    const _this = this;

    _this.$nextTick(async () => {
      _this.formState.projectResultId = _this.activeId;
    });
  },
  methods: {
    /**
     *
     * @param {*projectResultId remark auditingStatus}
     */

    async postCmsOrganizeOrganizeApproveApprove() {
      const _this = this;
      const { projectResultId, remark, auditingStatus } = _this.formState;
      if (auditingStatus !== 4 && !remark) {
        message.error('请填写驳回理由');
        return false;
      }
      const res = await apiPostCountyAuditing({
        projectResultId,
        auditingStatus,
        remark
      });
      console.log(res);
      if (res.code === '00000') {
        message.success('核准成功');
        _this.$emit('onOk');
      } else {
        // message.error('核准失败');
      }
    },

    /**
     * 点击确定
     */
    async onOk() {
      try {
        const _this = this;
        const { isOk } = await _this.$refs.girdFormEdit.validate();
        if (isOk) {
          //处理
          _this.postCmsOrganizeOrganizeApproveApprove();
        }
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 点击取消
     */
    onCancel() {
      try {
        const _this = this;
        _this.$emit('onCancel');
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.layout-modal {
  height: 40vh;
  padding: 20px;
  overflow-y: auto;
}
</style>
