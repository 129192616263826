<template>
  <section ref="content">
    <a-spin :spinning="loading" tip="加载中" size="large">
      <section class="wrap">
        <div class="headerText">县（市、区）初审（4月10日 - 4月16日）</div>
        <ZgyxSearchTablePagination
          ref="searchTablePagination"
          :formConfigs="[]"
          tableRowKey="projectResultId"
          :tableColumns="[
            { type: 'input', dataIndex: 'mySort', title: '序号', width: 40 },
            { type: 'input', dataIndex: ['requisition', 'orgName'], title: '申报单位名称', width: 100 },
            { type: 'input', dataIndex: ['requisition', 'orgPrincipal'], title: '单位负责人', width: 100 },
            { type: 'input', dataIndex: ['requisition', 'orgMobile'], title: '联系电话', width: 100 },
            { type: 'input', dataIndex: ['requisition', 'orgFax'], title: '传真', width: 100 },
            // { type: 'custom', dataIndex: 'orgAddress', title: '详细地址', width: 200 },
            { type: 'input', dataIndex: 'submitApplicationTime', title: '社区提交时间', width: 120 },
            { type: 'input', dataIndex: 'selfEvaluateScore', title: '社区自评总分', width: 120 },

            { type: 'input', dataIndex: 'countyEvaluateUserName', title: '区县联系人', width: 120 },
            { type: 'input', dataIndex: 'countyEvaluateUserMobile', title: '区县联系电话', width: 120 },

            { type: 'input', dataIndex: 'countyHandleStatusText', title: '审核状态', width: 100 },
            { type: 'custom', dataIndex: 'action', title: '操作', width: 200, fixed: 'right', align: 'center' }
          ]"
          :requestFunction="requestFunction"
          :scrollConfigs="{ x: 1800 }"
          :showDefaultButton="false"
        >
          <template #orgAddress="{ record }">
            <div class="county-wrap">
              {{ record.requisition.orgAddress }}
            </div>
          </template>
          <template #action="{ record }">
            <a-button type="primary" class="btn" size="small" @click="toDetailByLook(record)">详情</a-button>

            <a-popconfirm
              placement="top"
              ok-text="确定"
              cancel-text="取消"
              @confirm="countyAuditing(record)"
              v-if="record.canHandleType == 2"
            >
              <template #title> 确认向市级提交? </template>
              <a-button type="primary" danger class="btn" size="small">提交</a-button>
            </a-popconfirm>

            <a-button class="btn" size="small" type="primary" danger @click="handleDownloadItem(record)" v-preReClick>
              导出详情
            </a-button>
          </template>
        </ZgyxSearchTablePagination>
      </section>
    </a-spin>
    <auditModal :activeId="activeId" v-if="visibleModal" @onCancel="onCancelModal" @onOk="onOkModal" />
  </section>
</template>

<script>
import moment from 'moment';
import { message } from 'ant-design-vue';
import { mapGetters, mapActions } from 'vuex';
import { PlusOutlined } from '@ant-design/icons-vue';
import { apiGetCmsSelectionResultCountySearch, countyDetailDownLoad } from '@src/apis';
import { apiPostCountyAuditing } from '@src/apis';
import { downloadFile, isJSON } from '@src/utils';
import { ZgyxSearchTablePagination } from '@src/components';
import auditModal from './components/auditModal';
export default {
  name: '',
  components: { ZgyxSearchTablePagination, PlusOutlined, auditModal },
  data() {
    return {
      loading: false,
      activeId: '',
      visibleModal: false
    };
  },
  computed: {
    ...mapGetters({
      userTypes: 'userType'
    }),
    requestFunction() {
      return apiGetCmsSelectionResultCountySearch;
    }
  },
  mounted() {
    const _this = this;
    _this.$nextTick(() => {});
  },
  methods: {
    ...mapActions(['setActiveMenu']),
    async countyAuditing(record) {
      const _this = this;
      const res = await apiPostCountyAuditing({
        projectResultId: record.projectResultId,
        auditingStatus: 4,
        remark: ''
      });
      if (res.code === '00000') {
        message.success('核准成功');
        _this.$refs.searchTablePagination.getList();
      } else {
        // message.error('核准失败');
      }
    },
    /**
     * 取消回调
     */
    onCancelModal() {
      try {
        const _this = this;
        _this.visibleModal = false;
      } catch (error) {
        console.error(error);
      }
    },
    onOkModal() {
      try {
        const _this = this;
        _this.visibleModal = false;
        _this.$refs.searchTablePagination.getList();
      } catch (error) {
        console.error(error);
      }
    },
    showAudit(record) {
      const _this = this;
      _this.activeId = record.projectResultId;
      _this.visibleModal = true;
    },
    //下载详情
    async handleDownloadItem(record) {
      try {
        const _this = this;
        if (_this.loading) {
          return false;
        }
        _this.loading = true;
        const { status, data, headers } = await countyDetailDownLoad({ projectResultId: record.projectResultId });

        if (status === 200) {
          let filename = '';
          try {
            if (headers && headers['content-disposition']) {
              filename = headers['content-disposition'].split('=')[1];
            } else {
              filename = '';
            }
          } catch (error) {
            filename = '';
          }

          const { type } = data;

          new Blob([data], { type }).text().then((result) => {
            if (isJSON(result)) {
              const { message: msg } = JSON.parse(result);
              message.error(msg);
              _this.loading = false;
            } else {
              downloadFile(
                data,
                type,
                filename ? decodeURIComponent(filename) : moment(new Date()).format('YYYY-MM-DD'),
                () => {
                  message.success('导出成功');
                  _this.loading = false;
                }
              );
            }
          });
        } else {
          _this.loading = false;
          message.error('网络开小差了');
        }
      } catch (error) {
        console.error(error);
      }
    },
    toDetailByLook(record) {
      const { countyHandleStatus } = record;
      this.setActiveMenu({
        path: '/countyExamineDetail',
        name: '',
        query: { id: record.projectResultId, hideButton: countyHandleStatus == 4 ? 1 : 0 }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import './ExamineSearchComponent.less';
.county-wrap,
.city-wrap {
  width: 100%;
  height: auto;
  overflow-y: auto;
  max-height: 200px;
}
</style>
