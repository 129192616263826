<template>
  <ZgyxLayout pageName="市级审核" :breadcrumbs="['市级审核']">
    <ExamineSearchComponent />
  </ZgyxLayout>
</template>

<script>
import ZgyxLayout from '@src/components/zgyxLayout/ZgyxLayout.vue';
import ExamineSearchComponent from './ExamineSearchComponent.vue';
export default {
  name: 'ExamineSearch',
  components: {
    ZgyxLayout,
    ExamineSearchComponent
  }
};
</script>

<style lang="less" scoped></style>
